import React from "react";
import { Paper } from "@material-ui/core";

import Layout from "../components/Layout";
class Privacy extends React.Component {
	render() {
		return (
			<Layout showBrands={false} bg="#212322">
				<div className={"privacy-policy-root"}>
					<Paper className={"privacy-policy-paper"}>
						<p class="p1">
							<span class="s1">
								<strong>1. Importance of your privacy</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								At Bite, your privacy is of great importance to us. We understand that you entrust us
								with certain private information to help us provide various products and services. It is
								essential for us that you understand what personal information that we collect and why.
								&nbsp; In exchange for your trust, you expect and deserve our commitment to treat your
								information with respect and in accordance with this Privacy Policy. Rest assured that
								we will protect your privacy. &nbsp; For purpose of clarity, we have users and customers
								that use different portions of our service but both adhere to this Privacy Policy.
								&nbsp; Under no circumstances will Bite sell or share any personal information about you
								to or with any person or organization except: &nbsp; (i) as authorized by you, (ii) in
								connection with providing various products and/or services to you (the
								&ldquo;Service(s)&rdquo;), (iii) to the extent you make any use of Bite&rsquo;s mobile
								software application commonly referred to as &ldquo;Bite app&rdquo; or any other Bite
								mobile application (collectively the &ldquo;Application&ldquo;), or Bite&rsquo;s website
								(&ldquo;Website&rdquo;), (iv) to partners with which Bite has a business relationship
								(our customers), (v) as may be required by law or court order, or (vi) as otherwise set
								forth herein. &nbsp;
							</span>
						</p>
						<p class="p3">
							<span class="s1">
								<strong>
									The Privacy Policy below describes the standards we live up to in handling
									information about you and any personal information you submit through the Website
									and/or the Application and sets out the various purposes for which we may process
									your personal data.
								</strong>
							</span>
						</p>
						<p class="p4">
							<br />
						</p>
						<p class="p1">
							<span class="s1">
								<strong>2. A special note about children</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								Children are not eligible to use our Services and we ask that individuals under the age
								of 18 do not submit any personal information to us or use the Services. Although
								visitors of all ages may navigate through the Websites or use our Application, we do not
								knowingly collect or request personal information from those under the age of sixteen
								without parental consent. If, following a notification from a parent, guardian or
								discovery by other means, a child under 18 has been improperly registered on our site by
								using false information, we will cancel the child&rsquo;s account and delete the
								child&rsquo;s personal information from our records. &nbsp;
							</span>
						</p>
						<p class="p3">
							<span class="s1">
								<strong>
									Other age restrictions may be set forth in Bite&rsquo;s Terms of Use from time to
									time.
								</strong>
							</span>
						</p>
						<p class="p1">
							<span class="s1">
								<strong>3. The personal information we collect</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								There are several areas on the Website and/or within the Application where you may be
								asked to enter both personal and non-personal information. For more detailed information
								on the personal information we collect, please see the sections below.
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								<strong>3.1 Registration data</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								To use certain of Bite&rsquo;s Services and/or to access the Application and the
								Website, you&rsquo;ll need to provide certain personal information which may include,
								without limitation, your name, password, phone number and email address. &nbsp; If a
								user connects to the Services and/or the Application using credentials from a third
								party application (e.g. Facebook), the user will provide us with authentication
								information such as encrypted access credentials. We may also collect other information
								available on or through the user&rsquo;s third party application account, including, for
								example, name, profile picture and email address.
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								<strong>3.2 Technical information we collect</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								When you use Bite&rsquo;s Services and/or access the Application and the Website, we
								automatically collect certain technical information. &nbsp;{" "}
								<strong>(a) Usage data</strong> In order for Bite to ensure that we are providing the
								best possible consumer experience on the Website and within the Application, we collect
								and track certain user and customer information, such as IP address, general geographic
								location as indicated by the IP address, browser and/or mobile platform type, the
								webpages viewed on our websites, whether someone interacts with content available on our
								websites and how. &nbsp; In addition, when you use the Application Bite automatically
								receives and records information on our server logs from your browser or mobile
								platform, including information regarding your mobile or browser device, IP address and
								location. Bite also automatically collects and uses additional information from users
								and customers such as Application and/or Website open date and time, idle time, and
								clicks and/or swipes within the Application and/or Website (together with time and
								order). &nbsp; <strong>(b) Device information</strong> We may collect device-specific
								information, such as your hardware model, operating system version, unique device
								identifiers, language settings and mobile network information. &nbsp;{" "}
								<strong>(c) Cookies</strong> When you visit the Website, our Web server may send a
								cookie to your computer. This cookie stores a limited amount of information about your
								site visit and tracks aggregate information on how our site is being used, including
								session counts, navigation patterns, pages visited, etc. By using cookies we may collect
								information about the type of device you use to access our websites, the operating
								system and version, your IP address, your general geographic location as indicated by
								your IP address, your browser type, the webpages you view on our websites and whether
								you interact with content available on our websites and how. If you choose to set your
								web browser to not accept cookies, Bite can&rsquo;t guarantee that the Website will work
								as intended. &nbsp; <strong>(d) Weblogs</strong> Bite may also utilize web logs to track
								aggregate information about how our site and/or the Application is being used. Web logs
								track anonymous user information, including the number of visitors to the Website,
								operating systems, IP address (if publicly available), and browser type. Unlike cookies,
								web logs are maintained only on Bite servers and do not store or place anything on the
								user&rsquo;s computer. &nbsp; <strong>(e) Advertisement identification numbers</strong>{" "}
								If you come to the Bite Website or Application from an online advertisement, an
								anonymous identification number may be passed to the Website or Application. This
								information cannot be used to gather any personally identifiable information about you.
								It will only allow us to track the overall effectiveness of our online advertising,
								marketing and banner advertisement campaigns.
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								<strong>3.3 Other Information we get from your use of the Services</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								We may collect information about the Services that you use and how you use them. This
								information includes the following. &nbsp; <strong>(a) Payment data</strong> If you make
								a purchase through the Service, your credit or debit card information (such as card
								type) and other financial data that we need to process your payment may be collected and
								stored by us and/or the payment processors with which we work. In addition, the payment
								processors generally provide us with some limited information related to you, such as a
								unique token that enables you to make additional purchases using the information
								they&rsquo;ve stored, and your card&rsquo;s type, expiration date, and certain digits of
								your card number. &nbsp; <strong>(b) Information from surveys</strong> You may have the
								option of completing an online survey at the Website or within the Application from time
								to time. Participation in surveys is completely voluntary and it is your choice whether
								or not you wish to disclose your personal information to us in connection with any such
								survey. &nbsp;{" "}
								<strong>
									(c) Information from the &ldquo;Contact Us&rdquo; section of the Website or
									Application.
								</strong>{" "}
								You may contact Bite through the &ldquo;Contact Us&rdquo; feature for support issues or
								general inquires. Participation in this area is completely voluntary and it is your
								choice whether or not you wish to disclose your personal information to us. &nbsp; Bite
								may also collect information posted by users on social media sites for users that have
								opted to connect to or otherwise link Bite with social media accounts including Facebook
								and Twitter. &nbsp;{" "}
								<strong>(d) Information collected other sections of the Website or Application.</strong>{" "}
								Bite may, from time to time, make various resources available to you at the Website or
								within the Application. At times we may collect personal information in connection with
								providing such services.
							</span>
						</p>
						<p class="p5">
							<br />
						</p>
						<p class="p1">
							<span class="s1">
								<strong>4. Our legal grounds for handling personal information</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								There are different legal grounds that we rely on to use your personal information as
								stated hereunder.
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								<strong>4.1 Performance of a contract</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								The use of your personal information described herein may be necessary to perform the
								agreement you have with us. For example, to give you access to the Application, the
								Website and the Services, to register and maintain your account, to enable payment
								services, to help with support issues and to make sure that your Application performs as
								intended or to respond to your requests.
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								<strong>4.2 Legitimate interests</strong>
							</span>
						</p>
						<p class="p3">
							<span class="s1">
								<strong>
									We may use your personal information for our legitimate interests. For example, we
									rely on our legitimate interest to analyse and improve the Services and the content
									on our Website and the Application, to send you notifications about software updates
									or information about our Services or to use your personal information for
									administrative, fraud detection or legal purposes.
								</strong>
							</span>
						</p>
						<p class="p1">
							<span class="s1">
								<strong>5. How we use personal information</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								Bite uses the data we collect to provide you with the Application, the Website and the
								Services, which includes using data to improve and personalize your experience. We also
								use the data to communicate with you, for example, informing you about your account, new
								services, security and other types of updates.
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								<strong>5.1 Providing the Application and the Services</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								We use data to provide, operate, support and improve the Application, the Website and
								the Services to ensure that partners can sell food items to users as effortlessly as
								possible. Such data use also includes the handling of payment information for the
								purpose of being able to provide you with the Services. This processing is necessary for
								the performance of the agreement we have with you. &nbsp; Supplementary User Data may
								also be used to give users access to customized, personalized, or localized content,
								recommendations, features, and advertising through the Application and the Services.
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								<strong>5.2 Support services</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								We use data to respond to customer enquiries, diagnose Application/Service problems and
								provide other customer care and support services. This processing is necessary for the
								performance of the agreement we have with you, as well as to serve our legitimate
								interest.
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								<strong>5.3 Service Improvement</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								We continually use data for research purposes and to develop and improve our Services,
								including maintaining and improving the performance of the Application and the Services,
								developing and adding new features or capabilities. This processing is necessary to
								serve our legitimate interest.
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								<strong>5.4 Business Operations</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								We use data to develop aggregate analysis and business intelligence that enable us to
								operate, protect, make informed decisions, and report on the performance of our
								business. This processing is necessary to serve our legitimate interest.
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								<strong>6.5 Security, Safety and Dispute Handling</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								We use data to protect the security and safety of our users, customers, and the
								Application, to detect and prevent fraud, to resolve disputes and enforce our
								agreements. This processing is necessary to serve our legitimate interest. &nbsp; In
								addition, Bite may disclose account or other personal information when we have reason to
								believe that disclosing this information is necessary to identify, contact or bring
								legal action against someone who may be causing injury to or interference with
								Bite&rsquo;s rights or property, other Website or Application users or anyone else that
								could be harmed by such activities. This processing is necessary to serve our legitimate
								interest.
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								<strong>5.6 Communication, Marketing and Advertising</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								We use the data we collect to deliver and personalise our communications with you. For
								example, we may contact you by email or other means of electronic communication
								(including through the Application) to inform you about new features or services,
								security or software updates or invite you to take part in a survey. This processing is
								necessary to serve our legitimate interest. &nbsp;
							</span>
						</p>
						<p class="p3">
							<span class="s1">
								<strong>
									We may also share certain aggregated data with other third parties for general
									marketing and demographic purposes; however, this data, when shared on an aggregated
									basis, does not include any of your personal information.
								</strong>
							</span>
						</p>
						<p class="p1">
							<span class="s1">
								<strong>6. How we share personal information</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								&nbsp; Bite does not and will not sell personal information about our users and
								customers. We only disclose your data as described in this Privacy Policy. &nbsp; We do
								not sell or share any personal information about you to or with any person or
								organization except &nbsp; (i) as authorized by you, (ii) in connection with providing
								various products and/or services to you (the &ldquo;Service(s)&rdquo;), (iii) to the
								extent you make any use of Bite&rsquo;s mobile software application commonly referred to
								as &ldquo;Bite app&rdquo; or any other Bite mobile application (collectively the
								&ldquo;Application&ldquo;), or Bite&rsquo;s website (&ldquo;Website&rdquo;), (iv) to
								partners with which Bite has a business relationship (our customers), (v) as may be
								required by law or court order, or (vi) as otherwise set forth herein. &nbsp; In
								particular, and not in limitation of the foregoing, information you as a user enter at
								the Website and/or Application; &nbsp; (i) will be shared with the partners or third
								party service providers with which Bite has entered into a business relationship in
								order to provide the Services, (ii) may be shared with various third parties in
								connection with making available to you certain offers (which offers may be based on or
								related to your location at the time the offer is made available, unless you have opted
								out from receiving marketing information and/or Supplementary User Information). &nbsp;
							</span>
						</p>
						<p class="p3">
							<span class="s1">
								<strong>
									In addition, Bite may disclose or otherwise use non-personal information gathered on
									the Website or within the Application to third parties on an aggregated basis.
								</strong>
							</span>
						</p>
						<p class="p1">
							<span class="s1">
								<strong>7. International data transfers</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								&nbsp; Bite transfers, processes and stores information about its users and customers on
								servers located in a number of countries. Information collected within the European
								Union and European Economic Area (&ldquo;EEA&rdquo;), for example, might be transferred
								to and processed by third parties located in a country outside of the EU/EEA. We take
								great care in protecting your personal information and have put in place adequate
								mechanisms to protect it when it is transferred outside of the EU/EEA . For instance, by
								using the Standard Contractual Clauses as approved by the European Commission.
							</span>
						</p>
						<p class="p3">
							<span class="s1">
								<strong>&nbsp;</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">&nbsp;</span>
						</p>
						<p class="p1">
							<span class="s1">
								<strong>8. Security and retention of personal information</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								&nbsp; While no system is completely secure, Bite takes precautionary measures to help
								prevent personal information about you from loss, theft, misuse and unauthorised access.
								&nbsp; Bite will retain your personal information for as long as we deem it necessary to
								enable you to use the Website, the Application, to provide the Services to you, to
								comply with applicable laws (including those regarding document retention), resolve
								disputes with any parties and otherwise as necessary to allow us to conduct our
								business. &nbsp; If you have a question about Bite&rsquo;s security systems and specific
								retention period for certain types of personal information we process about you, please
								send an email to privacy@bite.se.
							</span>
						</p>
						<p class="p3">
							<span class="s1">
								<strong>&nbsp;</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">&nbsp;</span>
						</p>
						<p class="p1">
							<span class="s1">
								<strong>9. Your choices and how to alter the information you submit</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								<strong>9.1 Deleting your information</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								For purposes of clarity, you are entitled to terminate your Bite account at any time, at
								what point all information connected to your account will either be deleted our
								anonymised. However, binding legal requirements may require that we store certain
								information for various periods of time. In order to comply with these regulations, we
								may be unable to delete information from our databases. &nbsp;
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								<strong>9.2 Altering Information</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								You can always alter the information you have submitted when creating your Account by
								going to Settings in the Application Bite may alter, modify or delete any information
								you submit to the Website or through the Application if we believe, in our sole
								discretion, it is necessary or desirable to do so. &nbsp;
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								<strong>9.3 Opting out of sharing of Supplementary User Data</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								You can always opt out of Supplementary User Data collection by logging in to the
								Application and follow the steps listed below: 1. Go to Settings 2. Choose
								&ldquo;Personalisation settings&rdquo; or &ldquo;Handle notification preferences&rdquo;
								3. Opt out of the Supplementary User Data you want to &nbsp;
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								<strong>9.4 Altering your Communication, Marketing and Advertising Preferences</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								You can opt out of receiving direct marketing communications from Bite by following the
								instructions included in every email sent to you via the &ldquo;Unsubscribe&rdquo; tab.
								You can also opt out of email subscriptions or in-app messaging by updating your profile
								settings in the Application or on the Website or by contacting Bite. We respect your
								choice, and we will stop sending you promotional emails once you unsubscribe. &nbsp;
								Please note, regardless of your communication settings, we will continue to communicate
								with you regarding changes to terms and conditions, policy updates, service
								interruptions, data breaches or other significant information about our Services.
							</span>
						</p>
						<p class="p3">
							<span class="s1">
								<strong>&nbsp;</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">&nbsp;</span>
						</p>
						<p class="p1">
							<span class="s1">
								<strong>10. Your rights in relation to your personal information</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								We want you to be in control of how your personal information is used by us. Subject to
								local law, you can do this in the following ways: &nbsp; you can ask us for a copy of
								the personal information we hold about you by sending us a signed request you can inform
								us of any changes to your personal information, or if you want us to correct any of the
								personal information we hold about you; in certain situations, you can ask us to erase,
								block or restrict the personal information we hold about you, or object to particular
								ways in which we are using your personal information; and in certain situations, you can
								also ask us to send the personal information you have given us to a third party. &nbsp;
								Where we are using your personal information on the basis of your consent, you are
								entitled to withdraw that consent at any time. Moreover, where we process your personal
								information based on legitimate interest or the public interest, you have the right to
								object at any time to that use of your personal information. &nbsp; We rely on you to
								ensure that your personal information is complete, accurate and up to date. Please do
								inform us of any changes to or inaccuracies in your personal information by contacting
								privacy@bite.se. We will respond to your request as soon as possible but certainly
								within 30 days. &nbsp; Bite is committed to handle any request, complaint or concern
								that you may have about our use of your personal information in a fair and transparent
								way. If, however, you believe that we have not been able to assist with your complaint
								or concern, you may have the right to file a complaint with the data protection
								authority in your country (if one exists in your country) or supervisory authority.
							</span>
						</p>
						<p class="p3">
							<span class="s1">
								<strong>&nbsp;</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">&nbsp;</span>
						</p>
						<p class="p1">
							<span class="s1">
								<strong>11. Miscellaneous</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								<strong>11.1 Changes or updates to the Privacy Policy</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								Occasionally we may, in our discretion, make changes to our Privacy Policy, for example
								to reflect changes in the Services or regulatory requirements. When we update this
								Privacy Policy, we will revise the last update date. &nbsp; If there are major changes
								to the Privacy Policy or in how Bite uses your personal information, we will inform you
								either by posting a notice of such changes, prior to them taking place, or by directly
								sending you a notification. We encourage you to regularly review this Privacy Policy to
								learn more how Bite is using and protecting your information. &nbsp;
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								<strong>11.2 Business Transfer</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								We may, in the future, sell or otherwise transfer some or all of our assets or equity to
								a third party. Your personally identifiable information and other information we obtain
								from you via the Website or the Application may be disclosed to any potential or actual
								third party purchasers of such assets or equity and may be among the assets transferred.
								&nbsp;
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								<strong>11.3 Links</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								The Website and/or Application may contain links to other websites or applications that
								are owned and operated by third parties that are not affiliated with us. When you use
								those links, you leave the Website or Application (as applicable) and we are unable to
								control how the owners of the websites or applications you link to handle any
								information they gather about you, including their privacy practices and use of cookies.
								&nbsp; This Privacy Policy applies only to the Website and Application and does not
								govern activities on linked websites or applications. We encourage you to review the
								privacy policy of any other website or application you visit, download or access to
								understand its owner&rsquo;s practices on how it collects, uses and discloses your
								information.
							</span>
						</p>
						<p class="p3">
							<span class="s1">
								<strong>&nbsp;</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">&nbsp;</span>
						</p>
						<p class="p1">
							<span class="s1">
								<strong>12. How to contact us</strong>
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								If you have any questions about the use of your personal information, please send us an
								email to privacy@bite.se &nbsp; Unless otherwise stated, Philip Eriksson is the data
								controller for personal data we collect through the Services subject to this Privacy
								Policy.&nbsp;
							</span>
						</p>
						<p class="p2">
							<span class="s1">
								&nbsp; Thanks for taking the time to learn about our Privacy Policy. We hope feel more
								comfortable using our services now.
							</span>
						</p>
					</Paper>
				</div>
			</Layout>
		);
	}
}

export default Privacy;
